/** @format */

export const locations = [
  {
    map: "/locations/miami_map.png",
    city: "Miami",
    address: ["2915 Biscayne Boulevard,", "Suite 300", "Miami, FL 33137"],
    phone: "(305) 203-1563",
    image: "/locations/miami.png",

    Latitude: 25.8044981,
    bgImage: "/miami.png",
    Longitude: -80.1892029,
    slug: "miami",
    direction: "https://maps.app.goo.gl/USLqXDz4CRErJxsc7",
    reviewBtn: "https://g.page/r/CfpVdmNPJi8REAI/review",
    pageContents: `<h2>Miami, FL Tax Bankruptcy, Tax Controversy & Criminal Tax Attorneys</h2><br>
		<p><strong>Tax Workout Group</strong> has revolutionized how high-quality tax-related legal services are offered. By harnessing the power of technology, it fosters deep collaboration with its clients and team members from three main practice groups: Tax Bankruptcy, Tax Controversy, and Criminal Tax Defense. As tax issues can be a significant source of stress and frustration for you, the Tax Workout Group offers a convenient and stress-free approach to effectively resolve your tax problems.</p>
		<p>Through its innovative virtual approach, you can immediately access a diverse team of esteemed tax and bankruptcy attorneys with the experience, knowledge, and combined resources to strategically tackle any complex tax controversy, tax collection, tax bankruptcy, or criminal tax matter. Our three practice groups include former DOJ and US attorneys, IRS tax and bankruptcy attorneys, IRS tax auditors, IRS collection division personnel, Certified Public Accountants, experienced paralegals, and our award-winning administrative support staff. Say goodbye to your worries and let our tax professionals pave the way towards a financial future free of tax claims.</p>

		<h2>Tax Bankruptcy Services in Miami, FL</h2>
		<p>There exists a common misconception that taxes are never dischargeable in bankruptcy. To the contrary, you can usually discharge most federal, state, and local income taxes in Chapter 7, Chapter 13, and Chapter 11. Penalties and interest related to these are also dischargeable. Determining which tax claims are dischargeable and when to strategically file the bankruptcy to maximize the discharge involves an analysis of complex rules and a deep understanding of tax and bankruptcy law. </p>
		<p>We understand that your tax case is significant and stressful, and we recognize the trust and confidence you place in our firm. We assure you that we are entirely dedicated to your tax matter. To demonstrate our commitment and provide you peace of mind, we provide you convenient access to your case at any time via our Client Portal. We will regularly update you on the progress of your case.</p>
		<p>Our professionals at Tax Workout Group are here to assist with many types of tax matters, including:</p>

		<ul>
			<li>Tax Bankruptcy</li>
			<li>Tax Controversy</li>
			<li>Tax Compliance</li>
			<li>Criminal Tax Defense</li>
		</ul>

		<p>The Tax Workout Group’s virtual law firm model has gained rapid acceptance due to advancements in technology, such as the adoption of electronic court and government filing procedures among the IRS, most state tax agencies, and the federal court system — along with the legalization of digital signatures and other societal shifts. Leveraging improved law firm efficiencies enables us to provide more cost-effective legal representation.</p>
		<p>We employ various digital features, processes, and tools to ensure a positive remote experience and timely completion of requests. These virtual law firm features in our business model offer the added benefits of minimizing the need for in-person meetings and eliminating the hassle of physical paperwork, files, and document handling — promoting a seamless and secure online process: </p>

		<ul>
			<li>Videoconferencing (with searchable transcription for easy review of meetings)</li>
			<li>Sophisticated Law Firm Case Management Software</li>
			<li>Digital document creation and retention</li>
			<li>Enhanced secure client messaging and communications</li>
			<li>Automated calendaring with critical notifications and reminders</li>
			<li>Remote direct electronic U.S. document mailing</li>
			<li>Digital Signature Capture</li>
			<li>Electronic Court and Government Filing capabilities</li>
			<li>Cloud-based enterprise-level security and disaster recovery redundancies</li>
		</ul>

		<p>We support each engagement with a skillful team of professionals committed to exceptional service and headed by a lead tax attorney. This ensures effective representation for our clients and committed attention from our experienced and knowledgeable team members throughout each case. Additionally, each matter is assigned a Client Success Manager to guide the client throughout the legal tax engagement — explaining each step of the process and identifying any issues to solve them as soon as possible.</p>
		<p>You can expect personalized service and entry to the firm’s client portal platform that provides convenient 24/7 access to all necessary resources — a centralized hub for all of your needs.</p>
		<p>By operating in a virtual work environment, we eliminate excessive office space costs and save valuable time that would be wastefully spent on commuting. Leveraging advanced technology, tailored software, and analytical processes enables us to offer more cost-effective solutions without compromising on quality.</p>
		<p>Reach out to us today to schedule a complimentary 15-minute tax case consultation. During this session, we will evaluate your case, handle any inquiries, explore your various options, and determine the optimal legal strategy for your specific tax situation.</p>

		<h2>Our Review Sites</h2>

		<p><a target="_blank" href="https://www.yelp.com/biz/tax-workout-group-miami">Our Miami Tax Bankruptcy Law Services Yelp Listing</a><br>
		<a target="_blank" href="https://maps.app.goo.gl/USLqXDz4CRErJxsc7">Our Miami Tax Bankruptcy Law Services Google Listing</a><br></p>

		<h2>Proudly Serving</h2>

		<p>Miami and the surrounding communities in Florida.</p>

		<h2>Tax Workout Group</h2>
		<p>Miami Tax Bankruptcy, Tax Controversy & Criminal Tax Attorneys<br>

		2915 Biscayne Boulevard, Suite 300,<br>

		Miami, FL 33137<br>

		Phone: <a href='tel:3052031563'>(305) 203-1563</a>
		<br>

		Hours: Monday-Friday 8 AM-8 PM, Saturday 10 AM-6 PM</p>

		<h2>Nearby Places</h2>
		<h2>Restaurants</h2>

		<p><a target="_blank" href="https://www.google.com/maps/place/MC+Kitchen/@25.8144387,-80.1912116,17z/data=!3m1!4b1!4m6!3m5!1s0x88d9b157717dd21b:0x2f57a0b5cceac388!8m2!3d25.8144387!4d-80.1912116!16s%2Fg%2F119w03n9q?authuser=0&hl=en&entry=ttu">MC Kitchen</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Amara+at+Paraiso/@25.8064374,-80.1856884,17z/data=!3m1!4b1!4m6!3m5!1s0x88d9b22ee402e9ef:0xc39658cacb4641f2!8m2!3d25.8064374!4d-80.1856884!16s%2Fg%2F11hbjsklk1?authuser=0&hl=en&entry=ttu">Amara at Paraiso</a>,
		<a target="_blank" href="https://www.google.com/maps/place/SUGARCANE+raw+bar+grill/@25.806988,-80.193245,17z/data=!3m1!4b1!4m6!3m5!1s0x88d9b153805d4a31:0x19054c0631a1353a!8m2!3d25.806988!4d-80.193245!16s%2Fg%2F11gjhjj_bz?authuser=0&hl=en&entry=ttu">SUGARCANE raw bar grill</a></p>

		<h2>Hotels</h2>

		<p><a target="_blank" href="https://www.google.com/maps/place/Hampton+Inn+%26+Suites+Miami+Wynwood+Design+District/@25.8091813,-80.189772,17z/data=!3m1!4b1!4m9!3m8!1s0x88d9b155d6b369ef:0x62ed4f0d26bc7dda!5m2!4m1!1i2!8m2!3d25.8091813!4d-80.189772!16s%2Fg%2F11c6cxh5kx?authuser=0&hl=en&entry=ttu">Hampton Inn & Suites Miami Wynwood Design District</a>,
		<a target="_blank" href="https://www.google.com/maps/place/AC+Hotel+by+Marriott+Miami+Wynwood/@25.8088178,-80.1898816,17z/data=!3m1!4b1!4m9!3m8!1s0x88d9b1871e56f40b:0x4f7c87994ab44c1a!5m2!4m1!1i2!8m2!3d25.8088178!4d-80.1898816!16s%2Fg%2F11hdpnndjg?authuser=0&hl=en&entry=ttu">AC Hotel by Marriott Miami Wynwood</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Sonder+The+Palace/@25.8056298,-80.188389,17z/data=!3m1!4b1!4m9!3m8!1s0x88d9b1abcbcb9775:0xac6d2731d5542fd4!5m2!4m1!1i2!8m2!3d25.8056298!4d-80.188389!16s%2Fg%2F11rg2qzk0q?authuser=0&hl=en&entry=ttu">Sonder The Palace</a></p>

		<p><strong>Miami</strong>, situated in Miami-Dade County, Florida, is a vibrant city with an estimated population of 461,000 and the largest urban economy in the state. The city is known for its rich culture, diverse community, and miles of public beachfront, making it an attractive destination for tourists and locals. Miami's median age of 40.2 and the city's year-round tropical climate and robust economy draw a wide range of people from families and retirees to artists and entrepreneurs.
		<strong>Tax Workout Group</strong> proudly serves the Miami community for their <strong>Tax Bankruptcy</strong>, <strong>Tax Compliance</strong>, <strong>Tax Controversy</strong>, and <strong>Criminal Tax Defense</strong> needs.</p>`,
  },
  {
    map: "/locations/boca_map.png",
    city: "Boca Raton",
    address: [
      "150 East Palmetto Park Road,",
      "Suite 800",
      "Boca Raton, FL 33432",
    ],
    slug: "boca-raton",
    Latitude: 26.3501295,
    bgImage: "/boca.png",
    Longitude: -80.0868845,
    phone: "(561) 571-2004",
    image: "/locations/boca.png",
    direction: "https://maps.app.goo.gl/cL6zDtFHDQmqMTJcA",
    reviewBtn: "https://g.page/r/Ce16BD1duF_jEAI/review",
    shareBtn: "171476030050899",
    pageContents: `<h2>Boca Raton Tax Bankruptcy, Tax Controversy & Criminal Tax Attorneys</h2><br>
		<p>At <strong>Tax Workout Group</strong>, we have redefined how quality tax-related legal services are provided by employing technology to promote deep and meaningful collaboration among the team members comprising our three main practice groups: Tax Bankruptcy Group, Tax Controversy Group, and Criminal Tax Defense Group. Because tax issues are a source of deep stress and aggravation for many, we provide a convenient stress free method for determining a sound path to resolving your tax problems effectively.</p>
		<p>Our innovative virtual approach gives you access to a wide range of prominent tax and bankruptcy attorneys with the experience, relationships, knowledge, and collective resources to strategically meet your tax and bankruptcy law needs. Our three practice groups comprise former DOJ, US attorneys, IRS tax and bankruptcy attorneys, IRS tax auditors, IRS collection division personnel, Certified Public Accountants, experienced paralegals, and our award winning administrative support staff. Stop worrying about your tax issues and let our professionals guide you to a new financial future.</p>

		<h2>Tax Bankruptcy Services in Boca Raton, FL</h2>
		<p>We realize that your tax case can be the most important and stressful event in your life and that you are placing your faith and trust in our firm. Although we can assure you that we are on top of your matter, we also offer you peace of mind with easy, immediate access to your case at any time. We send you regular case updates through our Client Portal. You will quickly discover that we not only meet your expectations — we exceed them.</p>
		<p>Our professionals at <strong>Tax Workout Group</strong> can help you with the following tax issues:</p>

		<ul>
			<li><a href="/location/boca-raton/tax-bankruptcy">Tax Bankruptcy</a></li>
			<li><a href="/location/boca-raton/tax-controversy">Tax Controversy</a></li>
			<li>Tax Compliance</li>
			<li><a href="/location/boca-raton/criminal-tax-defense">Criminal Tax Defense</a></li>
		</ul>

		<p>Technological changes involving the implementation of electronic court and government filing procedures among the IRS, most state tax agencies, and the federal court system — along with the legalization of digital signatures and other societal changes — have accelerated the acceptance of our virtual law firm model, which enables us to deliver cost-effective legal representation through greater law firm efficiencies.</p>
		<p>We employ the following virtual law firm features to promote a positive remote experience while ensuring the timely completion of requests, reducing the need for face-to-face meetings, and eliminating the shuffling of paperwork, files, and documents:</p>

		<ul>
			<li>Videoconferencing (with searchable transcription for easy review of meetings)</li>
			<li>Sophisticated Law Firm Case Management Software</li>
			<li>Digital document creation and retention</li>
			<li>Digital Signature Capture</li>
			<li>Remote direct electronic U.S. document mailing</li>
			<li>Electronic Court and Government Filing capabilities</li>
			<li>Cloud-based enterprise-level security and disaster recovery redundancies</li>
			<li>Enhanced secure client messaging and communications</li>
			<li>Automated calendaring with critical notifications and reminders</li>
		</ul>

		<p>For each engagement, we assign a highly qualified team of professionals headed by a lead tax attorney so that every client receives extraordinary senior-level attention throughout the handling of their case. In addition, we provide our clients with the ability to log into our client portal platform with 24/7 access to everything on demand and centrally located through our client access tools.</p>
		<p>The virtual nature of our office allows us to reduce excess high-rent office space and eliminate wasteful commute time (and traffic) to and from the office. We can significantly minimize client fees and costs by combining these features with enhanced technology, customized software, and proprietary analytical processes.</p>
		<p>We are ready to map out the solution to your tax problem. Contact us today to schedule your free 15-minute consultation, during which we will evaluate your case, answer your questions, and determine the right legal strategy for your situation.</p>

		<h2>Our Review Sites</h2>
		<p><a target="_blank" target="blank" href="https://www.yelp.com/biz/tax-workout-group-p-a-boca-raton">Our Tax Bankruptcy Law Services Yelp Listing</a><br><a target="_blank" target="blank" href="https://maps.app.goo.gl/cL6zDtFHDQmqMTJcA">Our Tax Bankruptcy Law Services Google Listing</a></p>

		<h2>Proudly Serving</h2>
		<p>Boca Raton and the surrounding communities in Florida.</p>

		<h2>Tax Workout Group</h2>
		<address>Boca Raton Tax Bankruptcy, Tax Controversy & Criminal Tax Attorneys<br>150 East Palmetto Park Road, Suite 800,<br>Boca Raton, FL 33432<br>
		Phone: <a href='tel:5615712004'>(561) 571-2004</a><br>Hours: Monday-Friday 8 AM-8 PM, Saturday 10 AM-6 PM</address>

		<h2>Nearby Places</h2>
		<h2>Restaurants</h2>
		<p><a target="_blank" target="blank" href="https://www.google.com/maps/place/Yard+House/@26.35301,-80.08564,17z/data=!3m1!4b1!4m6!3m5!1s0x88d8e21cbccd3003:0x880cb00a56f2111e!8m2!3d26.35301!4d-80.08564!16s%2Fg%2F1hd_1dbml?authuser=0&hl=en&entry=ttu">Yard House</a>,
		<a target="_blank" target="blank" href="https://www.google.com/maps/place/Max%27s+Grille/data=!4m7!3m6!1s0x88d8e21cfd2a1219:0xbcc1c94eb4ff7ef5!8m2!3d26.3548905!4d-80.0848573!16s%2Fg%2F1tdkslhn!19sChIJGRIq_Rzi2IgR9X7_tE7Jwbw?authuser=0&hl=en&rclk=1">Max’s Grille</a>,
		<a target="_blank" target="blank" href="https://www.google.com/maps/place/Lemongrass+Asian+Bistro/data=!4m7!3m6!1s0x88d8e21af5ae03cd:0x9da418a5f5722f8!8m2!3d26.3476469!4d-80.085098!16s%2Fg%2F1td6t0bk!19sChIJzQOu9Rri2IgR-CJXX4pB2gk?authuser=0&hl=en&rclk=1">Lemongrass Asian Bistro</a></p>

		<h2>Hotels</h2>
		<p><a target="_blank" target="blank" href="https://www.google.com/maps/place/Hyatt+Place+Boca+Raton%2FDowntown/@26.35002,-80.0859895,17z/data=!3m1!4b1!4m9!3m8!1s0x88d8e21b114f789b:0xbf73cd04b8c1def!5m2!4m1!1i2!8m2!3d26.35002!4d-80.0859895!16s%2Fg%2F11c4bdm7rk?authuser=0&hl=en&entry=ttu">Hyatt Place Boca Raton/Downtown</a>,
		<a target="_blank" target="blank" href="https://www.google.com/maps/place/The+Boca+Raton+Bungalows/@26.3480317,-80.0777479,17z/data=!3m1!4b1!4m9!3m8!1s0x88d8e384b06ddd45:0xd63fdab05434b8e6!5m2!4m1!1i2!8m2!3d26.3480317!4d-80.0777479!16s%2Fg%2F11nxc79cvy?authuser=0&hl=en&entry=ttu">The Boca Raton Bungalows</a>,
		<a target="_blank" target="blank" href="https://www.google.com/maps/place/The+Boca+Raton/@26.3419485,-80.0774959,17z/data=!3m1!4b1!4m9!3m8!1s0x88d8e21660811273:0xaea9f9ef9bdcd6c9!5m2!4m1!1i2!8m2!3d26.3419485!4d-80.0774959!16zL20vMDNzX3ls?authuser=0&hl=en&entry=ttu">The Boca Raton</a></p>

		<p><strong>Boca Raton</strong>, located in Palm Beach County, Florida, is a charming town with a population of approximately 95,000. It is renowned as one of the finest places to reside in the state. Living in Boca Raton provides residents an urban ambiance, and most individuals own their homes. The town boasts abundant options for dining, coffee, and recreational areas, such as its several parks. Boca Raton is an appealing destination for both young professionals and retirees alike. Additionally, the public schools in Boca Raton have achieved high ratings, further enhancing the appeal of this wonderful town.
		<strong>Tax Workout Group</strong> proudly serves the Boca Raton community for their <strong>Tax Bankruptcy</strong>, <strong>Tax Compliancy</strong>, <strong>Tax Controversy</strong>, and <strong>Criminal Tax Defense</strong> needs.</p>`,
  },
  {
    map: "/locations/fort_map.png",
    city: "Fort Lauderdale",
    address: [
      "1 East Broward Boulevard,",
      "Suite 700",
      "Fort Lauderdale, FL 33301",
    ],
    bgImage: "/fort.png",
    Latitude: 26.1228001,
    Longitude: -80.1456642,
    slug: "fort-lauderdale",
    phone: "(954) 355-7137",
    image: "/locations/fort.png",
    direction: "https://maps.app.goo.gl/j7pFop7aybSNy5UFA",
    reviewBtn: "https://g.page/r/CU-B8JGH2B5ZEAI/review",
    pageContents: `<h2>Fort Lauderdale, FL Tax Bankruptcy, Tax Controversy & Criminal Tax Attorneys</h2><br>
		<p><strong>Tax Workout Group</strong> has revolutionized the way high-quality tax-related legal services are offered through advanced technology, fostering deep collaboration with its clients and its three main practice groups: Tax Bankruptcy, Tax Controversy, and Criminal Tax Defense. The Tax Workout Group understands that tax matters can cause you immense stress and frustration, so it offers a hassle-free approach to resolving your tax problems effectively.</p>
		<p>Through its innovative virtual platform, you can immediately access an esteemed network of renowned tax and bankruptcy attorneys with extensive experience, comprehensive knowledge, and the collective resources to tackle any complex tax controversy, tax collection, tax bankruptcy, or criminal tax matter. Our team includes former DOJ, US attorneys, IRS tax and bankruptcy attorneys, IRS tax auditors, IRS collection division personnel, Certified Public Accountants, skilled paralegals, and our exceptional administrative support staff. Allow our tax professionals to alleviate your worries and guide you towards a financial future free of tax claims.</p>

		<h2>Tax Bankruptcy Services in Fort Lauderdale, FL</h2>
		<p>Many people have the common misconception that taxes are never dischargeable in bankruptcy. To the contrary, you can discharge most federal, state, and local income taxes in Chapter 7, Chapter 13, and Chapter 11. Penalties and interest are also dischargeable. The task of determining which tax claims can be discharged and when to file the bankruptcy to maximize the discharge involves an analysis of complex rules and a thorough understanding of tax and bankruptcy law.</p>
		<p>We understand that dealing with your tax issues can be extremely stressful. It's crucial to us that you have faith and trust in our firm. Rest assured, we are fully committed to your tax matter. To demonstrate this, we provide you with peace of mind by offering easy and immediate access to your case through our Client Portal. We also send you regular updates about your case through this portal. When you choose Tax Workout Group, we seek to not only meet your expectations — but to exceed them. Our team of professionals can assist with many types of tax matters, including:</p>

		<ul>
			<li>Tax Bankruptcy</li>
			<li>Tax Controversy</li>
			<li>Tax Compliance</li>
			<li>Criminal Tax Defense</li>
		</ul>


		<p>Technological advancements have propelled the rapid adoption of the Tax Workout Group’s virtual law firm model, such as the implementation of electronic court and government filing systems among the IRS, the majority of state tax agencies, and the federal court system — along with the increasing acceptance of digital signatures and other societal changes. These changes enable us to provide cost-effective legal representation to you as we enhance law firm efficiencies.</p>

		<p>To ensure a smooth and efficient remote experience, we have implemented various digital features that facilitate timely completion of requests, minimize the need for in-person meetings, and eliminate the hassle of handling physical paperwork, files, and documents:</p>
		<ul>
			<li>Videoconferencing (with searchable transcription for easy review of meetings)</li>
			<li>Sophisticated Law Firm Case Management Software</li>
			<li>Digital document creation and retention</li>
			<li>Digital Signature Capture</li>
			<li>Remote direct electronic U.S. document mailing</li>
			<li>Electronic Court and Government Filing capabilities</li>
			<li>Cloud-based enterprise-level security and disaster recovery redundancies</li>
			<li>Enhanced secure client messaging and communications</li>
			<li>Automated calendaring with critical notifications and reminders</li>
		</ul>

		<p>To ensure effective representation and exceptional attention to detail, we assemble a team of highly-qualified professionals led by a seasoned tax attorney for every engagement. Each matter is also assigned a Client Success Manager to guide the client throughout the process, explaining every step and identifying any issues to solve them as soon as possible.</p>

		<p>Moreover, we provide you with personalized service and 24/7 access to our client portal platform, centralizing all essential resources and tools for your convenience.</p>

		<p>By embracing a virtual work environment, we eliminate the expenses associated with excessive office space and wasteful commute time, leading to these cost savings that we pass on to you. This is possible by utilizing advanced technology, tailored software, and exclusive analytical methods.</p>

		<p>Reach out to us today to schedule a complimentary 15-minute tax case consultation, during which we will assess your case, address any inquiries, explore your various options, and determine the right legal strategy for your specific tax situation.</p>

		<h2>Our Review Sites</h2>

		<p><a target="_blank" href="https://www.yelp.com/biz/tax-workout-group-fort-lauderdale">Our Fort Lauderdale Tax Bankruptcy Law Services Yelp Listing</a><br>
		<a target="_blank" href="https://maps.app.goo.gl/j7pFop7aybSNy5UFA">Our Fort Lauderdale Tax Bankruptcy Law Services Google Listing</a><br></p>

		<h2>Proudly Serving</h2>

		<p>Fort Lauderdale and the surrounding communities in Florida.</p>

		<h2>Tax Workout Group</h2>

		<p>Boca Raton Tax Bankruptcy, Tax Controversy & Criminal Tax Attorneys<br>
		1 East Broward Boulevard, Suite 700,<br>

		Fort Lauderdale, FL 33301<br>

		Phone: <a href='tel:9543557137'>(954) 355-7137</a><br>

		Hours: Monday-Friday 8 AM-8 PM, Saturday 10 AM-6 PM</p>

		<h2>Nearby Places</h2>

		<h2>Restaurants</h2>

		<p><a target="_blank" href="https://www.google.com/maps/place/Quarterdeck+Restaurants/@26.1019656,-80.1324775,17z/data=!3m1!4b1!4m6!3m5!1s0x88d901444fa31c7d:0x82e6c5196fcac7fd!8m2!3d26.1019656!4d-80.1324775!16s%2Fg%2F11h7jr559s?authuser=0&hl=en&entry=ttu">Quarterdeck Restaurants</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Doc+B's+Restaurant/@26.1276761,-80.1371009,17z/data=!3m1!4b1!4m6!3m5!1s0x88d901aca9ad09a5:0xaa5ba9064566ff69!8m2!3d26.1276761!4d-80.1371009!16s%2Fg%2F11c53cx97s?authuser=0&hl=en&entry=ttu">Doc B’s Restaurant</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Floridian+Restaurant/@26.1192308,-80.1287411,17z/data=!3m1!4b1!4m6!3m5!1s0x88d9004e585e6a41:0xf1ec0a34a678ac34!8m2!3d26.1192308!4d-80.1287411!16s%2Fg%2F1tcxkkdr?authuser=0&hl=en&entry=ttu">Floridian Restaurant</a></p>

		<h2>Hotels</h2>

		<p><a target="_blank" href="https://www.google.com/maps/place/Hampton+Inn+Ft.+Lauderdale%2FDowntown+Las+Olas+Area/@26.124828,-80.1431194,17z/data=!3m1!4b1!4m9!3m8!1s0x88d900ff77ef74ed:0x4253bae45d609f04!5m2!4m1!1i2!8m2!3d26.124828!4d-80.1431194!16s%2Fg%2F1thzvlc9?authuser=0&hl=en&entry=ttu">Hampton Inn Ft. Lauderdale/Downtown Las Olas Area</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Hyatt+Centric+Las+Olas+Fort+Lauderdale/@26.1189078,-80.1418872,17z/data=!3m1!4b1!4m9!3m8!1s0x88d90057ced1c15f:0x11c8bbed23f1ed86!5m2!4m1!1i2!8m2!3d26.1189078!4d-80.1418872!16s%2Fg%2F11j4dbpfh5?authuser=0&hl=en&entry=ttu">Hyatt Centric Las Olas Fort Lauderdale</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Riverside+Hotel+Fort+Lauderdale/@26.1187517,-80.1363851,17z/data=!3m1!4b1!4m9!3m8!1s0x88d900fa388d2b83:0x1ed3ff13c5df6dd3!5m2!4m1!1i2!8m2!3d26.1187517!4d-80.1363851!16s%2Fg%2F1tkmnbs5?authuser=0&hl=en&entry=ttu">Riverside Hotel Fort Lauderdale</a></p>

		<p><strong>Fort Lauderdale</strong>, the county seat and largest city of Broward County, boasts an estimated population of 186,760. Known for its stunning beaches, boating canals, upscale outdoor restaurants, bars, luxury hotels, and the Port Everglades cruise port, Fort Lauderdale has a reputation as a dynamic, vibrant community. The city's thriving economy and high-quality amenities draw a diverse range of residents, from professionals to retirees.
		<strong>Tax Workout Group</strong> proudly serves the Fort Lauderdale community for their <strong>Tax Bankruptcy</strong>, <strong>Tax Compliance</strong>, <strong>Tax Controversy</strong>, and <strong>Criminal Tax Defense</strong> needs.</p>`,
  },
  {
    map: "/locations/westpalm_map.png",
    city: "West Palm Beach",
    address: [
      "1645 Palm Beach Lakes Boulevard,",
      "Suite 1200",
      "West Palm Beach, FL 33401",
    ],
    Latitude: 26.7254904,
    bgImage: "/west.png",
    Longitude: -80.0842878,
    phone: "(561) 861-0926",
    slug: "west-palm-beach",
    image: "/locations/westpalm.png",
    direction: "https://maps.app.goo.gl/dQZh2qW5fqk3HG699",
    reviewBtn: "https://g.page/r/CfS2b6b0yQEYEAI/review",
    pageContents: `<h2>West Palm Beach, FL Tax Bankruptcy, Tax Controversy & Criminal Tax Attorneys</h2><br>
		<p><strong>Tax Workout Group</strong> has revolutionized the way high-quality tax-related legal services are offered by leveraging technology to foster effective collaboration with its clients and its three main tax practice groups: Tax Bankruptcy, Tax Controversy, and Criminal Tax Defense. As tax matters often cause significant stress and frustration for many people, the Tax Workout Group offers a convenient and hassle-free approach to achieving the best resolution of your tax problems.</p>
		<p>Through its innovative virtual approach, you gain immediate access to a diverse team of experienced tax and bankruptcy attorneys with the knowledge, experience and collective resources to tackle any complex tax controversy, tax collection, tax bankruptcy, or criminal tax matter. Our team consists of former DOJ and US attorneys, IRS tax and bankruptcy attorneys, IRS tax auditors, IRS collection division personnel, Certified Public Accountants, skilled paralegals, and our exceptional administrative support staff. Let go of your worries and let our tax professionals guide you toward a  financial future free of tax claims.</p>

		<h2>Tax Bankruptcy Services in West Palm Beach, FL</h2>
		<p>There is a common misconception that taxes are never dischargeable in bankruptcy. To the contrary, you can discharge most federal, state, and local income taxes in Chapter 7, Chapter 13, and Chapter 11. Penalties and interest are also dischargeable. Determining which tax claims can be discharged and when to file the bankruptcy to maximize the discharge involves an analysis of complex rules and a thorough understanding of tax and bankruptcy law.</p>

		<p>We understand that your tax issues can be significant and overwhelming, and you trust us to resolve your matter in the most favorable manner possible. We want to assure you that we are entirely dedicated to your tax matter. To demonstrate that this is the case, we offer you peace of mind by providing easy and immediate access to your case through our Client Portal. Regular updates will be sent through this portal, ensuring you stay well-informed. We promise to not only meet your expectations — but to exceed them.</p>

		<p>At Tax Workout Group, our team of professionals can assist with many types of tax matters, including:</p>

		<ul>
			<li>Tax Bankruptcy</li>
			<li>Tax Controversy</li>
			<li>Tax Compliance</li>
			<li>Criminal Tax Defense</li>
		</ul>

		<p>The Tax Workout Group virtual law firm model has gained rapid acceptance due to advancements in technology, such as the implementation of electronic court and government filing systems and the legalization of digital signatures among the IRS, most state tax agencies, and the federal court system. This model offers cost-effective legal representation through improved law firm efficiencies. These virtual law firm features reduce reliance on in-person meetings and eliminate physical document handling to ensure a seamless remote experience.  Some of these features include:</p>
		<ul>
			<li>Videoconferencing (with searchable transcription for easy review of meetings)</li>
			<li>Sophisticated Law Firm Case Management Software</li>
			<li>Enhanced secure client messaging and communications</li>
			<li>Automated calendaring with critical notifications and reminders</li>
			<li>Digital document creation and retention</li>
			<li>Digital Signature Capture</li>
			<li>Remote direct electronic U.S. document mailing</li>
			<li>Electronic Court and Government Filing capabilities</li>
			<li>Cloud-based enterprise-level security and disaster recovery redundancies</li>
		</ul>

		<p>To ensure effective representation and exceptional attention to detail, we assemble a team of highly qualified professionals headed by a lead tax attorney for every engagement. In addition, each matter is assigned a Client Success Manager to guide the client through the legal tax engagement, by explaining each step of the process,    identifying any issues, and solving them as soon as possible.</p>

		<p>While handling your case, you can expect personalized service and access to the firm’s client portal platform providing 24/7 access to all resources conveniently located in one place.</p>

		<p>By embracing a virtual work environment, we eliminate the cost associated with  excessive office space and wasteful time commuting back and forth to the office. This allows us to reduce client fees without compromising the quality of the services offered. Leveraging advanced technology, custom software, and proprietary analytical processes further contributes to these cost savings.</p>

		<p>Reach out to us today to schedule a complimentary 15-minute tax case consultation. We will evaluate your case, explore your various options, address your inquiries, and develop a tailored legal strategy to best address your specific tax situation.</p>

		<h2>Our Review Sites</h2>

		<p><a target="_blank" href="https://www.yelp.com/biz/tax-workout-group-west-palm-beach">Our West Palm Beach Tax Bankruptcy Law Services Yelp Listing</a><br>
		<a target="_blank" href="https://maps.app.goo.gl/dQZh2qW5fqk3HG699">Our West Palm Beach Tax Bankruptcy Law Services Google Listing</a><br></p>

		<h2>Proudly Serving</h2>

		<p>West Palm Beach and the surrounding communities in Florida.</p>

		<h2>Tax Workout Group</h2>
		<p>West Palm Beach Tax Bankruptcy, Tax Controversy & Criminal Tax Attorneys<br>

		1645 Palm Beach Lakes Boulevard, Suite 1200,<br>

		West Palm Beach, FL 33401<br>

		Phone: <a href='tel:5618610926'>(561) 861-0926</a><br>

		Hours: Monday-Friday 8 AM-8 PM, Saturday 10 AM-6 PM</p>

		<h2>Nearby Places</h2>

		<h2>Restaurants</h2>

		<p><a target="_blank" href="https://www.google.com/maps/place/Avocado+Grill/@26.7123626,-80.0502642,17z/data=!3m1!4b1!4m6!3m5!1s0x88d8d689711ce7e3:0xbb12650a26cf2142!8m2!3d26.7123626!4d-80.0502642!16s%2Fg%2F11b6gd8g7l?authuser=0&hl=en&entry=ttu">Avocado Grill</a>,
		<a target="_blank" href="https://www.google.com/maps/place/BJ's+Restaurant+%26+Brewhouse/@26.7226931,-80.0869396,17z/data=!3m1!4b1!4m6!3m5!1s0x88d8d6056132924d:0x84b599e19ef570f0!8m2!3d26.7226931!4d-80.0869396!16s%2Fg%2F11b5qvsndf?authuser=0&hl=en&entry=ttu">BJ’s Restaurant & Brewhouse</a>,
		<a target="_blank" href="https://www.google.com/maps/place/IL+Bellagio+Italian+Restaurant/@26.7080357,-80.0568956,17z/data=!3m1!4b1!4m6!3m5!1s0x88d8d660e4fbc425:0x4d4c5b7b6f4d2e31!8m2!3d26.7080357!4d-80.0568956!16s%2Fg%2F1tvm3kch?authuser=0&hl=en&entry=ttu">IL Bellagio Italian Restaurant</a></p>

		<h2>Hotels</h2>

		<p><a target="_blank" href="https://www.google.com/maps/place/Hilton+Garden+Inn+West+Palm+Beach+I95+Outlets/@26.7249033,-80.0835511,17z/data=!3m1!4b1!4m9!3m8!1s0x88d8d765fdd2ea6d:0xf5297a2bb0cc910e!5m2!4m1!1i2!8m2!3d26.7249033!4d-80.0835511!16s%2Fg%2F11fkpvnk9x?authuser=0&hl=en&entry=ttu">Hilton Garden Inn West Palm Beach I95 Outlets</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Canopy+by+Hilton+West+Palm+Beach+Downtown/@26.7069416,-80.0532744,17z/data=!3m1!4b1!4m9!3m8!1s0x88d8d7d34e877539:0x1ae11bf3daa9757c!5m2!4m1!1i2!8m2!3d26.7069416!4d-80.0532744!16s%2Fg%2F11g046xsmv?authuser=0&hl=en&entry=ttu">Canopy by Hilton West Palm Beach Downtown</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Hyatt+Place+West+Palm+Beach%2FDowntown/@26.7064246,-80.0518249,17z/data=!3m1!4b1!4m9!3m8!1s0x88d8d68ac8f84b25:0xf4ecb9aa5d36ba0d!5m2!4m1!1i2!8m2!3d26.7064246!4d-80.0518249!16s%2Fg%2F1tf5r0f0?authuser=0&hl=en&entry=ttu">Hyatt Place West Palm Beach/Downtown</a></p>

		<p><strong>West Palm Beach</strong>, located in Palm Beach County, Florida, is a vibrant city with an estimated population of over 100,000 residents. Known for its diverse communities like Andros Isle, Banyan Estates, and Hamilton Bay, West Palm Beach offers an appealing blend of urban conveniences and suburban tranquility. The city boasts numerous gated communities and is renowned for its community events, which make it a lively place to live. The area also features some of the oldest neighborhoods, such as Bel Air, Central Park, and El Cid. West Palm Beach's public schools are highly rated, making it an attractive location for families. The city's location, immediately to the west of Palm Beach and across the Lake Worth Lagoon, also attracts professionals seeking a balance of work and leisure and retirees drawn by the city's warm climate and wealth of activities.
		<strong>Tax Workout Group</strong> proudly serves the West Palm Beach community for their <strong>Tax Bankruptcy</strong>, <strong>Tax Compliance</strong>, <strong>Tax Controversy</strong>, and <strong>Criminal Tax Defense</strong> needs.</p>`,
  },
  {
    map: "/locations/jackson_map.png",
    city: "Jacksonville",
    // 50 North Laura Street, Suite 2500 Jacksonville, FL 32202
    address: ["841 Prudential Dr,", "Suite 1200,", "Jacksonville,", "FL 32207"],
    Latitude: 30.3271392,
    Longitude: -81.6626302,
    bgImage: "/locations/images/jacksonville-bg.jpg",
    phone: "(904) 544-3778",
    slug: "jacksonville",
    image: "/locations/images/jacksonville.jpg",
    direction: "https://maps.app.goo.gl/M7DpGixodRLBrVSE7",
    reviewBtn: "https://g.page/r/CUo9gZrbgWLYEAI/review",
    pageContents: `<h2>Jacksonville, FL Tax Bankruptcy, Tax Controversy & Criminal Tax Attorneys</h2><br>
		<p><strong>Tax Workout Group</strong> has revolutionized the way quality tax-related legal services are offered by incorporating technology to foster deep and meaningful collaboration with its clients and the team members in its three main practice groups: Tax Bankruptcy, Tax Controversy, and Criminal Tax Defense. Because tax matters can cause immense stress and frustration, we offer a hassle-free and convenient approach to effectively resolve your tax problems.</p>
		<p>Through its innovative virtual platform, you immediately gain access to a diverse range of esteemed tax and bankruptcy attorneys with the knowledge, experience, and collective resources necessary to strategically and effectively tackle any complex tax controversy, tax collection, tax bankruptcy, or criminal tax matter. Our practice groups include former DOJ and US attorneys, IRS tax and bankruptcy attorneys, IRS tax auditors, IRS collection division personnel, Certified Public Accountants (CPAs), skilled paralegals, and our highly-regarded administrative support staff. Let go of your worries; let our seasoned tax professionals guide you towards a financial future free of tax claims.</p>

		<h2>Tax Bankruptcy Services in Jacksonville, FL</h2>
		<p>There is a common misconception that taxes can never be discharged in bankruptcy. To the contrary, you can discharge most federal, state, and local income taxes in Chapter 7, Chapter 13, and Chapter 11. Penalties and interest can also be discharged. Determining which tax claims can be discharged and when to file the bankruptcy to maximize the discharge involves a thorough analysis of complex rules and a deep understanding of tax and bankruptcy law.</p>

		<p>We understand that your tax issues can be significant and overwhelming, and we appreciate your trust in our firm. Rest assured, our team remains fully committed to your tax matter. Moreover, we provide you with the convenience of easy and immediate access to your case through our Client Portal, along with regular updates. Count on us to not only meet your expectations, but exceed them.</p>

		<p>At Tax Workout Group, our team of seasoned professionals is well-equipped to assist you with many types of tax matters, including:</p>

		<ul>
			<li>Tax Bankruptcy</li>
			<li>Tax Controversy</li>
			<li>Tax Compliance</li>
			<li>Criminal Tax Defense</li>
		</ul>

		<p>The Tax Workout Group’s virtual law firm model has gained tremendous momentum due to technological advancements and the adoption of electronic court and government filing procedures among the IRS, most state tax agencies, and the federal court system. The acceptance of digital signatures and other societal changes have further hastened this acceleration. This model enables cost-effective legal representation through enhanced law firm efficiencies.</p>
		<p>To ensure a positive remote experience and prompt completion of requests, we incorporate various digital features that reduce reliance on in-person meetings and eliminate the need for physical handling of files and documents:</p>

		<ul>
			<li>Videoconferencing (with searchable transcription for easy review of meetings)</li>
			<li>Sophisticated Law Firm Case Management Software</li>
			<li>Automated calendaring with critical notifications and reminders</li>
			<li>Enhanced secure client messaging and communications</li>
			<li>Digital document creation and retention</li>
			<li>Digital Signature Capture</li>
			<li>Remote direct electronic U.S. document mailing</li>
			<li>Electronic Court and Government Filing capabilities</li>
			<li>Cloud-based enterprise-level security and disaster recovery redundancies</li>
		</ul>

		<p>In order to ensure that every client enjoys effective representation and exceptional attention from a highly-qualified team of professionals, a lead tax attorney will oversee each engagement to provide extraordinary senior-level support throughout the handling of every case. Additionally, each matter is assigned a Client Success Manager to guide the client through the legal tax engagement by explaining each step of the process and identifying any issues to solve them as soon as possible.</p>

		<p>Our clients enjoy personalized service and have 24/7 access to our client portal platform, which conveniently centralizes everything they need.</p>

		<p>Our virtual office environment allows us to reduce rent for excessive office space and eliminate time wasted on commuting, which minimizes client fees and costs without compromising the quality of services offered. Tax Workout Group provides efficient and cost-effective solutions by leveraging enhanced technology, customized software, and proprietary analytical processes.</p>

		<p>If you're facing a tax problem, our team is here to help. Contact Tax Workout Group today to schedule a complimentary 15-minute tax case consultation. During this session, we'll evaluate your case, address your inquiries, explore your various options, and develop the right tailored legal strategy for your specific tax situation.</p>

		<h2>Our Review Sites</h2>

		<p><a target="_blank" href="https://www.yelp.com/biz/tax-workout-group-jacksonville">Our Jacksonville Tax Bankruptcy Law Services Yelp Listing</a><br>
		<a href="https://maps.app.goo.gl/M7DpGixodRLBrVSE7" target="_blank">Our Jacksonville Tax Bankruptcy Law Services Google Listing</a><br></p>

		<h2>Proudly Serving</h2>

		<p>Jacksonville and the surrounding communities in Florida.</p>

		<h2>Tax Workout Group</h2>

		<p>Jacksonville Tax Bankruptcy, Tax Controversy & Criminal Tax Attorneys<br>

		841 Prudential Dr, Suite 1200,<br>

		Jacksonville, FL 32207<br>

		Phone: <a href='tel:9045443778'>(904) 544-3778</a><br>

		Hours: Monday-Friday 8 AM-8 PM, Saturday 10 AM-6 PM</p>

		<h2>Nearby Places</h2>

		<h2>Restaurants</h2>

		<p><a target="_blank" href="https://www.google.com/maps/place/The+Bread+%26+Board/@30.3263812,-81.6597543,17z/data=!3m1!4b1!4m6!3m5!1s0x88e5b77a03c7b3e9:0xa88bdb54bb7bc2c1!8m2!3d30.3263812!4d-81.6597543!16s%2Fg%2F11c7508hmw?authuser=0&hl=en&entry=ttu">The Bread & Board</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Burrito+Gallery+Downtown/@30.3277853,-81.6567624,17z/data=!3m1!4b1!4m6!3m5!1s0x88e5b7184b029883:0x18a2aac42ea41155!8m2!3d30.3277853!4d-81.6567624!16s%2Fg%2F1v_vtqh2?authuser=0&hl=en&entry=ttu">Burrito Gallery Downtown</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Jumpin'+Jax+House+of+Food+Downtown+Jacksonville/@30.3276664,-81.658171,17z/data=!3m1!4b1!4m6!3m5!1s0x88e5b72d513d6c03:0x7fe0332af1ff257d!8m2!3d30.3276664!4d-81.658171!16s%2Fg%2F11hf40mn12?authuser=0&hl=en&entry=ttu">Jumpin’ Jax House of Food Downtown Jacksonville</a></p>

		<h2>Hotels</h2>

		<p><a target="_blank" href="https://www.google.com/maps/place/Marriott+Jacksonville+Downtown/@30.3259472,-81.6618889,17z/data=!3m1!4b1!4m9!3m8!1s0x88e5b716c63680e5:0xe10a6260cf5deac2!5m2!4m1!1i2!8m2!3d30.3259472!4d-81.6618889!16s%2Fg%2F1td0z8xv?authuser=0&hl=en&entry=ttu">Marriott Jacksonville Downtown</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Hyatt+Regency+Jacksonville+Riverfront/@30.3241746,-81.655438,17z/data=!3m1!4b1!4m9!3m8!1s0x88e5b71bd258d8bd:0xa069f8dc7c6b32d6!5m2!4m1!1i2!8m2!3d30.3241746!4d-81.655438!16s%2Fg%2F11f61588q5?authuser=0&hl=en&entry=ttu">Hyatt Regency Jacksonville Riverfront</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Hilton+Garden+Inn+Jacksonville+Downtown+Southbank/@30.3125145,-81.6534752,17z/data=!3m1!4b1!4m9!3m8!1s0x88e5b706ba336d99:0x3374960c9064d8bf!5m2!4m1!1i2!8m2!3d30.3125145!4d-81.6534752!16s%2Fg%2F11b63cgb07?authuser=0&hl=en&entry=ttu">Hilton Garden Inn Jacksonville Downtown Southbank</a></p>

		<p><strong>Jacksonville</strong>, situated in Florida’s Duval County, is known for its reasonable cost of living, widely available beach access, and distinctly southern feel. With a  population of over 950,000, the city offers residents a suburban vibe, with an abundance of restaurants, parks, and safe neighborhoods. Jacksonville's impressive collection of beaches, parks, nature preserves, and hiking trails provide ample outdoor activities. Schools in St. Johns County — part of the greater Jacksonville area — are considered among the highest quality in Florida. The city attracts diverse people, including retirees, thanks to numerous retirement communities and professionals drawn by the city's robust economy.  <strong>Tax Workout Group</strong> proudly serves the Jacksonville community for their <strong>Tax Bankruptcy</strong>, <strong>Tax Compliance</strong>, <strong>Tax Controversy</strong>, and <strong>Criminal Tax Defense</strong> needs.</p>`,
  },
  {
    map: "/locations/orlando_map.png",
    city: "Orlando",
    address: ["121 South Orange Avenue,", "Suite 1500", "Orlando, FL 32801"],
    Latitude: 28.5417661,
    Longitude: -81.3815003,
    phone: "(321) 430-1045",
    bgImage: "/orlando.png",
    slug: "orlando",
    image: "/locations/orlando.png",
    direction: "https://maps.app.goo.gl/v37EbBH75Y2Xi5jm7",
    reviewBtn: "https://g.page/r/CdW-gHpvOXBTEAI/review",
    pageContents: `<h2>Orlando, FL Tax Bankruptcy, Tax Controversy & Criminal Tax Attorneys</h2><br>
		<p><strong>Tax Workout Group</strong> has revolutionized the way high-quality tax-related legal services are offered by utilizing technology to foster extensive and meaningful collaboration with its clients and its three main practice groups: Tax Bankruptcy, Tax Controversy, and Criminal Tax Defense. Since tax concerns create significant stress and frustration for numerous individuals, the Tax Workout Group offers a convenient and hassle-free approach to achieving resolution of your tax problems.</p>

		<p>Through its innovative virtual approach, you gain immediate access to a diverse team of highly experienced tax and bankruptcy attorneys with the knowledge, experience, and collective resources to strategically tackle any complex tax controversy, tax collection, tax bankruptcy, or criminal tax matter. Our three practice groups include former DOJ and US attorneys, IRS tax and bankruptcy attorneys, IRS tax auditors, IRS collection division professionals, Certified Public Accountants, experienced paralegals, and our exceptional administrative support staff. Leave your worries behind and let our professionals guide you towards a financial future free of tax claims.</p>

		<h2>Tax Bankruptcy Services in Orlando, FL</h2>
		<p>A common misconception causes many people to believe that taxes are never dischargeable in bankruptcy. To the contrary, you can usually discharge most federal, state, and local income taxes in Chapter 7, Chapter 13, and Chapter 11. Penalties and interest are also dischargeable. Determining which tax claims you can discharge and when to file the bankruptcy to maximize the amount discharged involves an analysis of complex rules and a comprehensive understanding of tax and bankruptcy law.</p>

		<p>We understand your tax issues can be overwhelming, and we recognize that you are trusting us to resolve your matter favorably and with optimum concern for your interests. We assure you that we are entirely dedicated to your tax matter, and we offer you peace of mind by providing immediate access to our Client Portal, where you can easily review your case at any time. We will send you regular updates through this portal, ensuring you remain well-informed. We are committed to not only meeting your expectations, but exceeding them. </p>
		<p>At Tax Workout Group, our experienced team of professionals can assist with many tax matters, including:</p>

		<ul>
			<li>Tax Bankruptcy</li>
			<li>Tax Controversy</li>
			<li>Tax Compliance</li>
			<li>Criminal Tax Defense</li>
		</ul>

		<p>The Tax Workout Group’s virtual law firm model has gained rapid acceptance due to the integration of electronic court and government filing processes among the IRS, most state tax agencies, and the federal court system — along with the legalization of digital signatures and other societal shifts. Leveraging these technological advancements, this model enables cost-effective legal representation through improved law firm efficiencies.</p>

		<p>We offer these virtual law firm features to reduce reliance on in-person meetings, eliminate physical document handling, and ensure a seamless remote experience with timely request fulfillment:</p>

		<ul>
			<li>Videoconferencing (with searchable transcription for easy review of meetings)</li>
			<li>Sophisticated Law Firm Case Management Software</li>
			<li>Enhanced secure client messaging and communications</li>
			<li>Automated calendaring with critical notifications and reminders</li>
			<li>Digital document creation and retention</li>
			<li>Digital Signature Capture</li>
			<li>Electronic Court and Government Filing capabilities</li>
			<li>Remote direct electronic U.S. document mailing</li>
			<li>Cloud-based enterprise-level security and disaster recovery redundancies</li>
		</ul>

		<p>We allocate a team of highly-skilled professionals for each engagement — headed by a lead tax attorney — to ensure that every client enjoys effective representation and exceptional attention. Additionally, each matter is assigned a Client Success Manager to guide the client through the legal tax engagement by explaining each step of the process and identifying any issues to solve them as soon as possible. Moreover, the firm’s client portal platform grants 24/7 access to a centralized location with all the necessary resources — always available at your fingertips.</p>
		<p>By embracing a virtual work environment, we eliminate costs associated with excessive high-rent office spaces and wasteful commuting to and from the office. We combine these advantages with cutting-edge technology, tailored software, and proprietary analytical processes to benefit you with significantly-reduced client fees and overall costs.</p>

		<p>Reach out to us today to schedule your complimentary 15-minute tax case consultation, where we will address your inquiries, explore your various options, and formulate a customized legal strategy tailored to your tax situation.</p>

		<h2>Our Review Sites</h2>

		<p><a target="_blank" href="https://www.yelp.com/biz/tax-workout-group-orlando">Our Orlando Tax Bankruptcy Law Services Yelp Listing</a><br>
		<a target="_blank" href="https://maps.app.goo.gl/v37EbBH75Y2Xi5jm7">Our Orlando Tax Bankruptcy Law Services Google Listing</a><br></p>

		<h2>Proudly Serving</h2>

		<p>Orlando and the surrounding communities in Florida.</p>

		<h2>Tax Workout Group</h2>

		<p>Orlando Tax Bankruptcy, Tax Controversy & Criminal Tax Attorneys<br>
		121 South Orange Avenue, Suite 1500,<br>

		Orlando, FL 32801<br>

		Phone: <a href='tel:3214301045'>(321) 430-1045</a><br>

		Hours: Monday-Friday 8 AM-8 PM, Saturday 10 AM-6 PM</p>

		<h2>Nearby Places</h2>
		<h2>Restaurants</h2>

		<p><a target="_blank" href="https://www.google.com/maps/place/310+Lakeside/@28.5419663,-81.3741733,17z/data=!3m1!4b1!4m6!3m5!1s0x88e77ae2d35ae4e9:0x918e1a6fd62ec6fe!8m2!3d28.5419663!4d-81.3741733!16s%2Fg%2F1tfrn4t1?authuser=0&hl=en&entry=ttu">310 Lakeside</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Artisan's+Table/@28.5404765,-81.3801679,17z/data=!3m1!4b1!4m6!3m5!1s0x88e77afe786073cb:0xe90e845b7b6db0f1!8m2!3d28.5404765!4d-81.3801679!16s%2Fg%2F1ptymkw_d?authuser=0&hl=en&entry=ttu">Artisan’s Table</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Fratelli's+Italian+Restaurant/@28.5470103,-81.3789262,17z/data=!3m1!4b1!4m6!3m5!1s0x88e77af97aa3cae3:0x1a7676099b590ca8!8m2!3d28.5470103!4d-81.3789262!16s%2Fg%2F1vyn4p4y?authuser=0&hl=en&entry=ttu">Fratelli’s Italian Restaurant</a></p>

		<h2>Hotels</h2>

		<p><a target="_blank" href="https://www.google.com/maps/place/Hilton+Garden+Inn+Orlando+Downtown/@28.5478079,-81.3764913,17z/data=!3m1!4b1!4m9!3m8!1s0x88e77afa51f32ea3:0x76762a3d39f25865!5m2!4m1!1i2!8m2!3d28.5478079!4d-81.3764913!16s%2Fg%2F11m9x1k_89?authuser=0&hl=en&entry=ttu">Hilton Garden Inn Orlando Downtown</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Grand+Bohemian+Hotel+Orlando,+Autograph+Collection/@28.5389083,-81.3782669,17z/data=!3m1!4b1!4m9!3m8!1s0x88e77b01fefd022b:0xca40d5cfca890aa4!5m2!4m1!1i2!8m2!3d28.5389083!4d-81.3782669!16s%2Fg%2F11cjj32hk5?authuser=0&hl=en&entry=ttu">Grand Bohemian Hotel Orlando, Autograph Collection</a>,
		<a target="_blank" href="https://www.google.com/maps/place/AC+Hotel+by+Marriott+Orlando+Downtown/@28.5388565,-81.3810806,17z/data=!3m1!4b1!4m9!3m8!1s0x88e77b19eebb1ba3:0x3505e6c0b65fa1d2!5m2!4m1!1i2!8m2!3d28.5388565!4d-81.3810806!16s%2Fg%2F11qm0kq1jc?authuser=0&hl=en&entry=ttu">AC Hotel by Marriott Orlando Downtown</a></p>

		<p><strong>Orlando</strong>, known as "The City Beautiful," is located in Orange County, Florida, and hosts an estimated population of 307,573. This vibrant city offers residents an urban-suburban mix feel and is considered among Florida's favorite places to live. Orlando's pleasant weather allows locals to enjoy the numerous lakes and outdoor activities year-round. The city is home to world-renowned attractions like Walt Disney World and Universal Orlando Resort, but it's not all about theme parks; it also boasts a growing tech sector attracting professionals. The city's excellent healthcare resources and affordable cost of living make it an attractive destination for families and retirees.
		<strong>Tax Workout Group</strong> proudly serves the Orlando community for their <strong>Tax Bankruptcy</strong>, <strong>Tax Compliance</strong>, <strong>Tax Controversy</strong>, and <strong>Criminal Tax Defense</strong> needs.</p>`,
  },
  {
    map: "/locations/tampa_map.png",
    city: "Tampa",

    address: ["100 N Ashley Drive", "Suite 600", "Tampa, FL 33602"],
    Latitude: 27.9446434,
    Longitude: -82.4603587,
    phone: "(813) 720-8320",
    bgImage: "/tampa.png",
    image: "/locations/tampa.png",
    slug: "tampa",
    direction: "https://maps.app.goo.gl/tC9w5yGZHC5QEqJr7",
    reviewBtn: "https://g.page/r/CUS8El921c2hEAI/review",
    pageContents: `<h2>Tampa, FL Tax Bankruptcy, Tax Controversy & Criminal Tax Attorneys</h2><br>
		<p><strong>Tax Workout Group</strong> has revolutionized the way high-quality tax-related legal services are offered by leveraging technology to foster extensive collaboration with its clients and the members of its three main practice groups: Tax Bankruptcy, Tax Controversy, and Criminal Tax Defense. Because tax matters often cause significant stress and frustration, The Tax Workout Group offers hassle-free and convenient methods to effectively resolve your tax problems.</p>

		<p>Through its innovative virtual approach, you gain immediate access to a diverse team of highly-experienced tax and bankruptcy attorneys with the knowledge, experience, and combined resources necessary to strategically tackle any complex tax controversy, tax collection, tax bankruptcy, or criminal tax matter. Our team consists of former DOJ and US attorneys, IRS tax and bankruptcy attorneys, IRS tax auditors, IRS collection division personnel, Certified Public Accountants, experienced paralegals, and our exceptional administrative support staff. Let go of your worries and trust our tax professionals to guide you towards a financial future free of tax claims.</p>

		<h2>Tax Bankruptcy Services in Tampa, FL</h2>
		<p>There is a common misconception that taxes are never dischargeable in bankruptcy. To the contrary, you can discharge most federal, state, and local income taxes in Chapter 7, Chapter 13, and Chapter 11. Penalties and interest are also dischargeable. Determining which tax claims can be discharged and when to file the bankruptcy to maximize the discharge involves an analysis of complex rules and a thorough understanding of tax and bankruptcy law. </p>

		<p>We understand that your tax issues can be overwhelming, and you are trusting us to favorably resolve your matter with optimum concern for your interests. We assure you that we are entirely dedicated to your tax matter. To demonstrate that, we offer you peace of mind by providing easy and immediate access to your case through our Client Portal, where you will remain well-informed as we send you regular updates. We always strive not only to meet your expectations — but to exceed them.</p>

		<p>At Tax Workout Group, our team of professionals can assist with many types of tax matters, including:</p>

		<ul>
			<li><a href="/location/tampa/tax-bankruptcy">Tax Bankruptcy</a></li>
			<li><a href="/location/tampa/tax-controversy">Tax Controversy</a></li>
			<li>Tax Compliance</li>
			<li><a href="/location/tampa/criminal-tax-defense">Criminal Tax Defense</a></li>
		</ul>

		<p>The Tax Workout Group’s virtual law firm model has gained rapid acceptance due to technological advancements such as electronic court and government filing procedures adopted among the IRS, the majority of state tax agencies, and the federal court system. Along with the legalization of digital signatures and other societal changes, this model has gained momentum to offer cost-effective legal representation through improved law firm efficiencies.</p>

		<p>These virtual law firm features ensure a seamless remote experience and timely completion of requests, while reducing reliance on in-person meetings and eliminating physical document handling:</p>

		<ul>
			<li>Videoconferencing (with searchable transcription for easy review of meetings)</li>
			<li>Sophisticated Law Firm Case Management Software</li>
			<li>Digital document creation and retention</li>
			<li>Digital Signature Capture</li>
			<li>Electronic Court and Government Filing capabilities</li>
			<li>Enhanced secure client messaging and communications</li>
			<li>Automated calendaring with critical notifications and reminders</li>
			<li>Remote direct electronic U.S. document mailing</li>
			<li>Cloud-based enterprise-level security and disaster recovery redundancies</li>
		</ul>

		<p>As part of our commitment to provide exceptional attention to every client throughout the entire case process, we assemble a team of highly-qualified professionals to handle each engagement — headed by a lead tax attorney. Additionally, each matter is assigned a Client Success Manager to guide the client through the legal tax engagement by explaining each step of the process and identifying any issues in order to solve them as soon as possible.</p>
		<p>You can expect personalized service and entry to the firm’s client portal platform, ensuring you can access all essential resources 24/7 — conveniently located in one place.</p>

		<p>By embracing a virtual work environment, we eliminate the costs associated with excessive office spaces and wasteful time commuting to and from the office. This allows us to reduce client fees and costs without compromising the quality of services offered. Leveraging advanced technology, customized software, and proprietary analytical processes further contributes to these cost savings.</p>
		<p>Reach out to us today to schedule a free 15-minute tax case consultation. We will evaluate your case, address your inquiries, explore your various options, and develop the right tailored legal strategy for your specific tax situation.</p>

		<h2>Our Review Sites</h2>

		<p><a target="_blank" href="https://www.yelp.com/biz/tax-workout-group-tampa">Our Tampa Tax Bankruptcy Law Services Yelp Listing</a><br>
		Our <a target="_blank" href="https://maps.app.goo.gl/tC9w5yGZHC5QEqJr7">Tampa Tax Bankruptcy Law Services Google Listing</a><br></p>

		<h2>Proudly Serving</h2>

		<p>Tampa and the surrounding communities in Florida.</p>

		<h2>Tax Workout Group</h2>

		<p>
		Tampa Tax Bankruptcy, Tax Controversy & Criminal Tax Attorneys<br>

		100 S Ashley Drive, Suite 600,<br>

		Tampa, FL 33602<br>

		Phone: <a href='tel:8137208320'>(813) 720-8320</a><br>

		Hours: Monday-Friday 8 AM-8 PM, Saturday 10 AM-6 PM</p>

		<h2>Nearby Places</h2>
		<h2>Restaurants</h2>

		<p><a target="_blank" href="https://www.google.com/maps/place/211+Restaurant/@27.9466948,-82.4576988,17z/data=!3m1!4b1!4m6!3m5!1s0x88c2c48b8ab600dd:0xfc47c642a04e27a4!8m2!3d27.9466948!4d-82.4576988!16s%2Fg%2F11c57pz0bj?authuser=0&hl=en&entry=ttu">211 Restaurant</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Taps+Restaurant+Bar+%26+Lounge/@27.9500125,-82.4606692,17z/data=!3m1!4b1!4m6!3m5!1s0x88c2c4899e53320b:0xc73294ca5a74d273!8m2!3d27.9500125!4d-82.4606692!16s%2Fg%2F1ts1t03t?authuser=0&hl=en&entry=ttu">Taps Restaurant Bar & Lounge</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Yah+Mon+Caribbean+Restaurant/@27.9422145,-82.4629635,17z/data=!3m1!4b1!4m6!3m5!1s0x88c2c36e28ded9f5:0xd146d5e88ecf8cf5!8m2!3d27.9422145!4d-82.4629635!16s%2Fg%2F11ckscw4mh?authuser=0&hl=en&entry=ttu">Yah Mon Caribbean Restaurant</a></p>

		<h2>Hotels</h2>

		<p><a target="_blank" href="https://www.google.com/maps/place/Embassy+Suites+by+Hilton+Tampa+Downtown+Convention+Center/@27.9423153,-82.4547605,17z/data=!3m1!4b1!4m9!3m8!1s0x88c2c48d150dadab:0x55a71c747ca9dd92!5m2!4m1!1i2!8m2!3d27.9423153!4d-82.4547605!16s%2Fg%2F1tf7sxzm?authuser=0&hl=en&entry=ttu">Embassy Suites by Hilton Tampa Downtown Convention Center</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Hyatt+Place+Tampa+Downtown/@27.9474884,-82.4566012,17z/data=!3m1!4b1!4m9!3m8!1s0x88c2c48b9ba3e617:0x1eec4200cb271007!5m2!4m1!1i2!8m2!3d27.9474884!4d-82.4566012!16s%2Fg%2F11lgrk7dnz?authuser=0&hl=en&entry=ttu">Hyatt Place Tampa Downtown</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Hilton+Tampa+Downtown/@27.9465944,-82.4579406,17z/data=!3m1!4b1!4m9!3m8!1s0x88c2c48c735357cd:0xf0a9cb965c7ff366!5m2!4m1!1i2!8m2!3d27.9465944!4d-82.4579406!16s%2Fg%2F1tdvkzfw?authuser=0&hl=en&entry=ttu">Hilton Tampa Downtown</a></p>

		<p><strong>Tampa</strong>, located in Hillsborough County, Florida, is a bustling metropolis with an estimated population of 3.19 million people living in the Tampa Bay Metro region, encompassing four counties: Hillsborough, Pinellas, Pasco, and Hernando. The city offers a harmonious blend of urban and suburban feel, mixed with the amenities of a large metropolitan area — including professional sports teams. Tampa is renowned for its year-round festival season and an impressive food scene. The city's beautiful weather — with summer highs around 90 degrees and winter lows in the 60s — is another attractive aspect. Tampa's median age of 35 suggests a youthful, vibrant community. The combination of big-city life and small-town charm makes Tampa an appealing choice for professionals and retirees.
		<strong>Tax Workout Group</strong> proudly serves the Tampa community for their <strong>Tax Bankruptcy</strong>, <strong>Tax Compliance</strong>, <strong>Tax Controversy</strong>, and <strong>Criminal Tax Defense</strong> needs.</p>`,
  },
  {
    map: "/locations/tallahassee_map.png",
    city: "Tallahassee",

    address: [
      "113 South Monroe Street,",
      "First Floor",
      "Tallahassee, FL 32301",
    ],
    Latitude: 30.4412061,
    bgImage: "/tallahassee.png",
    Longitude: -84.2829774,
    slug: "tallahassee",
    phone: "(850) 299-0862",
    image: "/locations/tallahassee.png",
    direction: "https://maps.app.goo.gl/yvzTqJsZMu24MXb77",
    reviewBtn: "https://g.page/r/CbR5OUq5RSg4EAI/review",
    pageContents: `<h2>Tallahassee, FL Tax Bankruptcy, Tax Controversy & Criminal Tax Attorneys</h2><br>
		<p><strong>Tax Workout Group</strong> has revolutionized the way high-quality tax-related legal services are offered by leveraging technology that fosters deep and meaningful collaboration with its clients and the team members in its three main practice groups — Tax Bankruptcy, Tax Controversy, and Criminal Tax Defense. Because tax problems can cause significant stress and frustration, the Tax Workout Group offers a convenient and hassle-free method to achieve effective resolution of your tax problems.</p>

		<p>Through its innovative virtual approach, you gain immediate access to a diverse team of highly-skilled tax and bankruptcy attorneys that leverage their extensive experience, valuable resources, and profound tax and bankruptcy law knowledge to strategically tackle any complex tax controversy, tax collection, tax bankruptcy, or criminal tax matter. Our team consists of former DOJ and US attorneys, IRS tax and bankruptcy attorneys, IRS tax auditors, IRS collection division personnel, Certified Public Accountants, seasoned paralegals, and dedicated administrative support staff. Leave your worries behind, and let our tax professionals guide you towards a financial future free of tax claims.</p>

		<h2>Tax Bankruptcy Services in Tallahassee, FL</h2>
		<p>It is a common misconception that taxes are never dischargeable in bankruptcy. To the contrary, you can usually discharge most federal, state, and local income taxes in Chapter 7, Chapter 13, and Chapter 11. Penalties and interest are also dischargeable. Determining which tax claims can be discharged and strategizing when to file the bankruptcy to maximize the discharge involves an analysis of complex rules and a comprehensive understanding of tax and bankruptcy law.</p>

		<p>At Tax Workout Group, we understand that your tax case may be extremely stressful for you, and we recognize you are trusting our firm to favorably resolve your matter. We assure you we are fully dedicated to your tax matter. To demonstrate this is the case, we offer you peace of mind by providing easy and instant access to your case through our Client Portal. We will send you regular updates through this portal to keep you well-informed. We pride ourselves in not only meeting your expectations, but exceeding them.</p>

		<p>At Tax Workout Group, our team of professionals assists you with many types of tax matters, including:</p>

		<ul>
			<li>Tax Bankruptcy</li>
			<li>Tax Controversy</li>
			<li>Tax Compliance</li>
			<li>Criminal Tax Defense</li>
		</ul>

		<p>The Tax Workout Group’s virtual law firm model has gained rapid acceptance due to technological advancements such as the adoption of electronic court and government filing processes among the IRS, the majority of state tax agencies, and the federal court system — in addition to the legalization of digital signatures and other societal shifts. This model offers cost-effective legal representation through improved law firm efficiencies.</p>

		<p>These virtual law firm features foster a seamless remote experience, not only ensuring timely fulfillment of requests, but also reducing reliance on in-person meetings while eliminating physical document handling:</p>

		<ul>
			<li>Videoconferencing (with searchable transcription for easy review of meetings)</li>
			<li>Automated calendaring with critical notifications and reminders</li>
			<li>Sophisticated Law Firm Case Management Software</li>
			<li>Enhanced secure client messaging and communications</li>
			<li>Digital document creation and retention</li>
			<li>Digital Signature Capture</li>
			<li>Remote direct electronic U.S. document mailing</li>
			<li>Electronic Court and Government Filing capabilities</li>
			<li>Cloud-based enterprise-level security and disaster recovery redundancies</li>
		</ul>

		<p>We ensure effective representation and exceptional attention to detail for every client engagement by assembling a team of highly-qualified professionals headed by a lead tax attorney. Additionally, each matter is assigned a Client Success Manager to guide the client through the legal tax engagement by explaining each step in the process and identifying any issues to solve them as soon as possible.</p>

		<p>You can expect personalized service and entry to the firm’s client portal platform, conveniently granting you 24/7 access to all resources and tools at your fingertips in one central location.</p>

		<p>By embracing a virtual work environment, we eliminate excessive office space costs and wasteful commuting to and from the office. This allows us to reduce client fees without compromising quality of the services offered. Leveraging advanced technology, customized software, and our proprietary analytical processes further contributes to these cost savings.</p>

		<p>Reach out to us today to schedule your complimentary 15-minute tax case consultation, where we will evaluate your case, address your inquiries, explore your various options, and devise the right tailored legal strategy for your specific tax situation.</p>

		<h2>Our Review Sites</h2>

		<p><a target="_blank" href="https://www.yelp.com/biz/tax-workout-group-tallahassee">Our Tallahassee Tax Bankruptcy Law Services Yelp Listing</a><br>
		<a target="_blank" href="https://maps.app.goo.gl/yvzTqJsZMu24MXb77">Our Tallahassee Tax Bankruptcy Law Services Google Listing</a><br></p>

		<h2>Proudly Serving</h2>

		<p>Tallahassee and the surrounding communities in Florida.</p>

		<h2>Tax Workout Group</h2>

		<p>Tallahassee Tax Bankruptcy, Tax Controversy & Criminal Tax Attorneys<br>

		113 South Monroe Street, First Floor,<br>

		Tallahassee, FL 32301<br>

		Phone: <a href='tel:8502990862'>(850) 299-0862</a><br>

		Hours: Monday-Friday 8 AM-8 PM, Saturday 10 AM-6 PM</p>

		<h2>Nearby Places</h2>
		<h2>Restaurants</h2>

		<p><a target="_blank" href="https://www.google.com/maps/place/Brick+House+Restaurant+%26+Bar/@30.4409033,-84.2859438,17z/data=!3m1!4b1!4m6!3m5!1s0x88ecf573d605ba89:0x2ec9330f4cb4d77d!8m2!3d30.4409033!4d-84.2859438!16s%2Fg%2F11cly5__j0?authuser=0&hl=en&entry=ttu">Brick House Restaurant & Bar</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Miller's+Ale+House/@30.4387231,-84.2722749,17z/data=!3m1!4b1!4m6!3m5!1s0x88ecf57d192fdd05:0x25fdc1001ad63fae!8m2!3d30.4387231!4d-84.2722749!16s%2Fg%2F1tfk0p6w?authuser=0&hl=en&entry=ttu">Miller’s Ale House</a>,
		<a target="_blank" href="https://www.google.com/maps/place/The+Edison+-+Restaurant+Tallahassee/@30.433656,-84.2773831,17z/data=!3m1!4b1!4m6!3m5!1s0x88ecf57ca84bc02f:0x6b4672c5c09c281a!8m2!3d30.433656!4d-84.2773831!16s%2Fg%2F11bw14_3gq?authuser=0&hl=en&entry=ttu">The Edison - Restaurant Tallahassee</a></p>

		<h2>Hotels</h2>

		<p><a target="_blank" href="https://www.google.com/maps/place/AC+Hotel+by+Marriott+Tallahassee+Universities+at+the+Capitol/@30.4349914,-84.2780727,17z/data=!3m1!4b1!4m9!3m8!1s0x88ecf5651c6be8ab:0xed55b3c53192edef!5m2!4m1!1i2!8m2!3d30.4349914!4d-84.2780727!16s%2Fg%2F11nz0lkqlf?authuser=0&hl=en&entry=ttu">AC Hotel by Marriott Tallahassee Universities at the Capitol</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Hyatt+House+Tallahassee+Capitol+-+University/@30.4326399,-84.2899453,17z/data=!3m1!4b1!4m9!3m8!1s0x88ecf56ca07d96f7:0x4976994cafcbd75d!5m2!4m1!1i2!8m2!3d30.4326399!4d-84.2899453!16s%2Fg%2F11l0mk9917?authuser=0&hl=en&entry=ttu">Hyatt House Tallahassee Capitol - University</a>,
		<a target="_blank" href="https://www.google.com/maps/place/Residence+Inn+by+Marriott+Tallahassee+Universities+at+the+Capitol/@30.435885,-84.2899656,17z/data=!3m1!4b1!4m9!3m8!1s0x88ecf56d1879d5ed:0xb1f77b8bc45ad595!5m2!4m1!1i2!8m2!3d30.435885!4d-84.2899656!16s%2Fg%2F1v1kqpn9?authuser=0&hl=en&entry=ttu">Residence Inn by Marriott Tallahassee Universities at the Capitol</a></p>

		<p><strong>Tallahassee</strong>, the capital of Florida, is located in Leon County and has an estimated population of 201,731. The city offers residents an urban-suburban mix, making it a popular place to live in Florida. Tallahassee boasts two universities and a local college. Tallahassee has a reputation for being more of a Southern city than a typical Florida city, characterized by its laid-back vibe, dignified buildings, grid streets, and a canopy of large trees. The city's public schools rank well, contributing to its appeal to families and professionals. Residents often wear the city's colors: garnet and gold. In addition, Tallahassee shines as a beacon of affordability, offering a reasonable cost of living, which attracts both professionals and retirees. Forbes has recognized Tallahassee as one of Florida's greatest places to live.</p>
		<p><strong>Tax Workout Group</strong> proudly serves the Tallahassee community for their <strong>Tax Bankruptcy</strong>, <strong>Tax Compliance</strong>, <strong>Tax Controversy</strong>, and <strong>Criminal Tax Defense</strong> needs.</p>`,
  },
  {
    map: "/locations/images/naples-map.png",
    city: "Naples",

    address: ["780 Fifth Avenue,", "Suite 200", "Naples, FL  33401"],
    Latitude: 26.1413397,
    bgImage: "/locations/images/naples-bg.jpg",
    Longitude: -81.7968604,
    slug: "naples",
    phone: "(239) 799-1251",
    image: "/locations/images/naples.jpeg",
    direction: "https://maps.app.goo.gl/tunF9yZ1z3rHRSog7",
    reviewBtn: "https://g.page/r/CeCeyUuVvK_YEAI/review",
    pageContents: `<p><strong>Tax Workout Group</strong> has revolutionized the way high-quality tax-related legal services are offered by leveraging technology that fosters deep and meaningful collaboration with its clients and the team members in its three main practice groups — Tax Bankruptcy, Tax Controversy, and Criminal Tax Defense. Because tax problems can cause significant stress and frustration, the Tax Workout Group offers a convenient and hassle-free method to achieve effective resolution of your tax problems.</p>

		<p>Through its innovative virtual approach, you gain immediate access to a diverse team of highly-skilled tax and bankruptcy attorneys that leverage their extensive experience, valuable resources, and profound tax and bankruptcy law knowledge to strategically tackle any complex tax controversy, tax collection, tax bankruptcy, or criminal tax matter. Our team consists of former DOJ and US attorneys, IRS tax and bankruptcy attorneys, IRS tax auditors, IRS collection division personnel, Certified Public Accountants, seasoned paralegals, and dedicated administrative support staff. Leave your worries behind, and let our tax professionals guide you towards a financial future free of tax claims.</p>
		
		<h2>Tax Bankruptcy Services in Naples, FL</h2>
		
		<p>It is a common misconception that taxes are never dischargeable in bankruptcy. To the contrary, you can usually discharge most federal, state, and local income taxes in Chapter 7, Chapter 13, and Chapter 11. Penalties and interest are also dischargeable. Determining which tax claims can be discharged and strategizing when to file the bankruptcy to maximize the discharge involves an analysis of complex rules and a comprehensive understanding of tax and bankruptcy law.</p>
		
		<p>At Tax Workout Group, we understand that your tax case may be extremely stressful for you, and we recognize you are trusting our firm to favorably resolve your matter. We assure you we are fully dedicated to your tax matter. To demonstrate this is the case, we offer you peace of mind by providing easy and instant access to your case through our Client Portal. We will send you regular updates through this portal to keep you well-informed. We pride ourselves in not only meeting your expectations, but exceeding them.</p>
		
		<p>At Tax Workout Group, our team of professionals assists you with many types of tax matters, including:</p>
		
		<ul>
			<li>Tax Bankruptcy</li>
			<li>Tax Controversy</li>
			<li>Tax Compliance</li>
			<li>Criminal Tax Defense</li>
		</ul>
		
		<p>The Tax Workout Group’s virtual law firm model has gained rapid acceptance due to technological advancements such as the adoption of electronic court and government filing processes among the IRS, the majority of state tax agencies, and the federal court system — in addition to the legalization of digital signatures and other societal shifts. This model offers cost-effective legal representation through improved law firm efficiencies.</p>
		
		<p>These virtual law firm features foster a seamless remote experience, not only ensuring timely fulfillment of requests, but also reducing reliance on in-person meetings while eliminating physical document handling:</p>
		
		<ul>
			<li>Videoconferencing (with searchable transcription for easy review of meetings)</li>
			<li>Automated calendaring with critical notifications and reminders</li>
			<li>Sophisticated Law Firm Case Management Software</li>
			<li>Enhanced secure client messaging and communications</li>
			<li>Digital document creation and retention</li>
			<li>Digital Signature Capture</li>
			<li>Remote direct electronic U.S. document mailing</li>
			<li>Electronic Court and Government Filing capabilities</li>
			<li>Cloud-based enterprise-level security and disaster recovery redundancies</li>
		</ul>
		
		<p>We ensure effective representation and exceptional attention to detail for every client engagement by assembling a team of highly-qualified professionals headed by a lead tax attorney. Additionally, each matter is assigned a Client Success Manager to guide the client through the legal tax engagement by explaining each step in the process and identifying any issues to solve them as soon as possible.</p>
		
		<p>You can expect personalized service and entry to the firm’s client portal platform, conveniently granting you 24/7 access to all resources and tools at your fingertips in one central location.</p>
		
		<p>By embracing a virtual work environment, we eliminate excessive office space costs and wasteful commuting to and from the office. This allows us to reduce client fees without compromising the quality of the services offered. Leveraging advanced technology, customized software, and our proprietary analytical processes further contributes to these cost savings.</p>
		
		<p>Reach out to us today to schedule your complimentary 15-minute tax case consultation, where we will evaluate your case, address your inquiries, explore your various options, and devise the right tailored legal strategy for your specific tax situation.</p>
		
		<h2>Our Review Sites</h2>
		
		<ul>
			<li><a href="https://www.yelp.com/biz/tax-professional-group-naples" target="_blank">Our Naples Tax Bankruptcy Law Services Yelp Listing</a></li>
			<li><a href="https://maps.app.goo.gl/tunF9yZ1z3rHRSog7" target="_blank">Our Naples Tax Bankruptcy Law Services Google Listing</a></li>
		</ul>
		
		<h2>Proudly Serving</h2>
		
		<p>Naples and the surrounding communities in Florida.</p>
		
		<h2>Tax Workout Group</h2>
		
		<h3>Naples Tax Bankruptcy, Tax Controversy & Criminal Tax Attorneys</h3>
		
		<p>780 Fifth Avenue, Suite 200<br>
		Naples, FL  33401<br>
		Phone: <a href='tel:2397991251'>(239) 799-1251</a><br>

		Hours: Monday-Friday 8 AM-8 PM, <br>Saturday 10 AM-6 PM</p>
		
		<h2>Nearby Places</h2>
		
		<h3>Restaurants</h3>
		
		<ul>
		<li><a href="https://maps.app.goo.gl/jdVn9gwNHTn16zpS8" target="_blank">Le Colonial Naples</a></li>
		<li><a href="https://maps.app.goo.gl/YVnXhBRdNYErz45t6" target="_blank">Bar Tulia</a></li>
		<li><a href="https://maps.app.goo.gl/ziDZ1jxtEYkv7aHb7" target="_blank">Bistro 821</a></li>
		</ul>
		
		<h3>Hotels</h3>
		
		<ul>
		<li><a href="https://maps.app.goo.gl/oRxCug1dLpeue4eu6" target="_blank">Inn on Fifth</a></li>
		<li><a href="https://maps.app.goo.gl/DPS39vXLYRST7Mmb7" target="_blank">Hyatt House Naples/5th Avenue</a></li>
		<li><a href="https://maps.app.goo.gl/YQ9GSa6nYhCv8UjD9" target="_blank">Bellasera Hotel</a></li>
		</ul>
		
		<p>Naples, located in Collier County, Florida, is known for its high-end shopping, dining, and white sand beaches. With an estimated population of 22,088, Naples offers residents an urban-suburban mix, making it a popular place to live in Florida. Naples boasts several top-rated schools and is known for its quality of life, beautiful landscapes, and cultural offerings. The city's public schools rank well, contributing to its appeal to families and professionals. In addition, Naples shines as a beacon of affordability, offering a reasonable cost of living, which attracts both professionals and retirees. Forbes has recognized Naples as one of Florida's greatest places to live.</p>
		
		<p>Tax Workout Group proudly serves the Naples community for their Tax Bankruptcy, Tax Compliance, Tax Controversy, and Criminal Tax Defense needs.</p>
		`,
  },
];
